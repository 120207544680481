body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.site-layout-content {
  min-height: 280px;
  /* padding: 24px; */
  /* background: #fff; */
}
#components-layout-demo-top .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}
.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.backpic {
  background-image: url('./components/photoback.jpg');
}

.alphaback {
  opacity: 1;
 	background: rgb(220, 220, 220); /* Цвет фона */
  margin: auto;
  max-width: 950px;
  padding: 10px;
  border-radius: 5px
}

.alphaback2 {
 	background: rgb(0, 0, 0) /* Цвет фона */
}

.shallow_gray {
  background: rgb(168, 174, 166); /* Цвет фона */
} 

.deep_gray {
      background: rgb(92, 103, 87); /* Цвет фона */
     }

 
     .ant-table-thead> tr >th{
      font-weight: bold !important;
       color: rgb(254, 254, 254) !important;
       background: black !important;
       opacity: 0.9 !important;
     

     }
     @media screen and (max-width: 500px) {
      .ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td{
    
         padding: 5px !important;
         /* white-space: nowrap !important; */
         overflow: hidden !important;
         text-overflow: ellipsis !important;
         
       }
    }

     .ant-table {
      font-weight: bold !important;
       color: black!important;
      background: rgb(208, 212, 206)!important;
      opacity: 0.85!important;
    }