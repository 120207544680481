.simpleMap {
  width: 100%;
  height: 100vh;
  margin: auto;
  max-width: 950px;
}

.leaflet-container {
  height: 600px;
  width: 100%;
}

.leaflet-div-icon {
  background: transparent;
  border: none;
}