.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.site-layout-content {
  min-height: 280px;
  /* padding: 24px; */
  /* background: #fff; */
}
#components-layout-demo-top .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}
.ant-row-rtl #components-layout-demo-top .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

/* img {
  max-width: none;

  min-width: auto;
  min-height: auto;

  height: 100%;

  top: 50%;
  left: 50%;

  transform: translate(-50%, -50%);
} */

.simple-linear {
  background: linear-gradient(blue, pink);
}

@media (min-width: 500px) {
  .bigmenu {
    width: "500px";
    font-size: "large";
  }
}

@media (max-width: 500px) {
  .bigmenu {
    font-size: "medium";
  }
}

.ant-divider-horizontal {
  display: flex;
  clear: both;
  width: 100%;
  min-width: 100%;
}

.ant-layout-header {
 padding: 0 20px;
}

audio {
  filter: sepia(20%) saturate(70%) grayscale(1) contrast(99%) invert(12%);
  width: 100%;
  height: 25px;
}

.ant-image {
  position: relative;
  display: grid;
}

.ant-form-item {
  margin-bottom: 0px !important;
}

.ant-page-header {
  padding: 8px 20px !important;
}

.ant-list-vertical .ant-list-item-meta-title {
  margin-bottom: 0px !important;
}

.iframe-placeholder
{
   background: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" preserveAspectRatio="xMaxYMax meet"><rect fill="#00022E" stroke="#00022E" stroke-width="3" width="30" height="30" x="25" y="85"><animate attributeName="opacity" calcMode="spline" dur="0.8" values="1;0;1;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.4"></animate></rect><rect fill="#00022E" stroke="#00022E" stroke-width="3" width="30" height="30" x="85" y="85"><animate attributeName="opacity" calcMode="spline" dur="0.8" values="1;0;1;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.2"></animate></rect><rect fill="#00022E" stroke="#00022E" stroke-width="3" width="30" height="30" x="145" y="85"><animate attributeName="opacity" calcMode="spline" dur="0.8" values="1;0;1;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="0"></animate></rect></svg>') 0px 0px no-repeat;
}

.__react_modal_image__header {
  top: 15px;
}



/* .ant-list-lg .ant-list-item {
  padding: 16px 12px;
} */

.rc-collapse>.rc-collapse-item {
  border-bottom: 5px solid #d9d9d9;
}

.rc-collapse>.rc-collapse-item>.rc-collapse-header {
  display: flex;
  align-items: center;
  line-height: 22px;
  cursor: pointer;
  color: black;
}

.rc-collapse-content {
  overflow: hidden;
  color: black;
  padding: 0 8px;
}

.rc-collapse-content>.rc-collapse-content-box {
  margin-top: 8px;
  margin-bottom: 8px;
}

img.rot {
  animation: 3s linear 0s normal none infinite running rot;
  -webkit-animation: 3s linear 0s normal none infinite running rot;
 
}
@keyframes rot {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rot {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.ant-upload-list-picture-container {
  width: 85%;
}

details summary { 
  cursor: pointer;
}

summary::-webkit-details-marker {
  display: none
}

details > summary {
  padding: 5px;
  background-color: #eee;
  color: #333;
  border: 1px #ccc solid;
  cursor: pointer;
}

details > div {
  border: 1px #ccc solid;
  padding: 10px;
}
details[open] > summary {
  color:#eee; 
  background-color:#333;
}

.carousel .slide { display: flex; justify-content: center; } 